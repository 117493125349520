import React from "react"
import { GatsbyImage } from "gatsby-plugin-image";


const DownloadLink = ({ download }) => {
    let featuredImgFluid = download.frontmatter.featuredImage.childImageSharp.gatsbyImageData
    return (
        <div className="col-lg-12 col-md-6">
            <div className="single-blog-post">
                <a href={download.frontmatter.attachments.publicURL} download>
                <div className="row">
                    
                    <div className="col-lg-2 col-md-6">
                        
                        <div className="post-image">
                            <GatsbyImage image={featuredImgFluid} alt={"Download-" + download.frontmatter.title} />
                        </div>
                    </div>
                    <div className="col-lg-10 col-md-6">
                        <h3> {download.frontmatter.title} </h3>

                        <div dangerouslySetInnerHTML={{ __html: download.html }} />

                    </div>
                
                    </div>
                </a>
            </div>
        </div>
    )
}

export default DownloadLink

