import React from "react";
import PropTypes from "prop-types";

// Utilities
//import kebabCase from "lodash/kebabCase"

// Components
import { graphql } from "gatsby";
import Layout from "../components/App/Layout";
import Navbar from "../components/App/Navbar";
import PageBanner from "../components/Common/PageBanner";
import Footer from "../components/App/Footer";
import { useTranslation } from "gatsby-plugin-react-i18next";
import DownloadLink from "../components/Current/download-link";

const TagsDownloadPage = ({ data }) => {
  const { t } = useTranslation();
  const { edges } = data.postData;
  //const group = data.tagData.group
  return (
    <Layout>
      <Navbar />
      <PageBanner
        pageTitle={t("Downloads")}
        homePageText="Home"
        homePageUrl="/"
        activePageText={t("Aktuell")}
      />
      {/*Noch Tags sortieren hinzufügen?*/}
      {/*<div className="widget-area">*/}
      {/*    <div className="container widget widget_tag_cloud">*/}
      {/*        <h3 className="widget-title">{t("Tags")}</h3>*/}

      {/*        <div className="tagcloud">*/}
      {/*            {group.map(tag => (*/}
      {/*                <Link key={Math.random()} to={`/meters/${kebabCase(tag.fieldValue)}/`}>*/}
      {/*                    <span className="post-count"> {tag.fieldValue} ({tag.totalCount}) </span>*/}
      {/*                </Link>*/}
      {/*            ))}*/}
      {/*        </div>*/}
      {/*    </div>*/}
      {/*</div>*/}
      <div className="blog-area ptb-100">
        <div className="container">
          <div className="row">
            {edges.map(({ node }) => (
              <DownloadLink key={Math.random()} download={node} />
            ))}
          </div>
        </div>
      </div>
      <Footer />
    </Layout>
  );
};

TagsDownloadPage.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      group: PropTypes.arrayOf(
        PropTypes.shape({
          fieldValue: PropTypes.string.isRequired,
          totalCount: PropTypes.number.isRequired,
        }).isRequired
      ),
    }),
  }),
};

export default TagsDownloadPage;

export const pageQuery = graphql`
  query ($language: String!) {
    tagData: allMarkdownRemark(
      limit: 2000
      filter: {
        frontmatter: { language: { eq: $language } }
        fileAbsolutePath: { regex: "/(/meters/)/" }
      }
    ) {
      group(field: frontmatter___tags) {
        fieldValue
        totalCount
      }
    }
    postData: allMarkdownRemark(
      sort: { order: ASC, fields: [frontmatter___priority] }
      filter: {
        frontmatter: { language: { eq: $language } }
        fileAbsolutePath: { regex: "/(/downloads/)/" }
      }
    ) {
      edges {
        node {
          html
          frontmatter {
            link
            attachments {
              publicURL
            }
            title
            priority
            featuredImage {
              childImageSharp {
                gatsbyImageData(width: 800, layout: CONSTRAINED)
              }
            }
          }
        }
      }
    }
    locales: allLocale {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
